import css from 'styled-jsx/css';

export default css`
  .menu-wrap-center-logo {
    background-image: var(--logo-login);
    height: 45px;
    background-repeat: no-repeat;
    width: 100%;
    max-width: 150px;
    background-size: contain;
    background-position: center;
    cursor: pointer;
  }
  .sidenav {
    top: 0px;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: fixed;
    min-height: 100vh;
    background: var(--bg-sidenav);
    border-right: var(--line);
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: 11;
    padding-bottom: 80px;
    transition: 0.3s;
    box-sizing: border-box;
  }
  .sidenav.active {
    z-index: 11;
    transform: translateX(0px);
  }
  .sidenav.inactive {
    z-index: 11;
    transform: translateX(-100%);
  }
  .sidenav::-webkit-scrollbar {
    width: 0.1em;
    position: absolute;
  }
  .sidenav::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
    position: absolute;
  }
  .sidenav::-webkit-scrollbar-thumb {
    background-color: var(--foreground);
    outline: 1px solid var(--foreground);
    position: absolute;
  }
  .sidenav__top-section {
    position: sticky;
    height: 62px;
    background: var(--background-utils);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 16px 1em 22px;
    z-index: 2;
    border-bottom: var(--line-header);
  }
  @media only screen and (min-width: 992px) {
    .sidenav__top-section {
      padding: 1em 1em;
    }
    .sidenav {
      width: 250px;
    }
  }
  .overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgb(0, 0, 0, 50%);
    -webkit-transition: opacity 0.4s;
    transition: opacity 0.4s;
    z-index: 11;
  }
  .user-menu-title {
    cursor: pointer;
  }
  .user-menu-title__icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 100px;
    color: white;
    justify-content: center;
    background: #fff3;
    transition: 0.3s;
    box-shadow: var(--shadow-light);
  }
  .user-menu-title p {
    font-size: 15px;
    letter-spacing: 0.5px;
    font-weight: 600;
    white-space: nowrap;
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .overlay-body {
    overflow: hidden;
  }
  .menu {
    list-style-type: none;
    text-align: left;
    padding: 0;
  }
  .menu > div {
    padding: 1.1875rem 1.25rem;
    margin: 0px;
    display: flex;
    cursor: pointer;
    user-select: none;
    align-items: center;
    font-size: 1rem;
    transition: 0.3s;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
    color: var(--li-sidenav);
    border-bottom: 1px solid #ebebeb;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .menu li.active {
    font-weight: 700;
  }
  .category > div {
  }
  .divider {
    width: 90%;
    height: 1px;
    margin: auto;
    background: #afcaf838;
  }
  .card-reseller {
    margin: 1em;
    border-radius: 6px;
    border: var(--line);
    position: relative;
  }
  .card-reseller:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.05;
    background-image: url(/images/home/new/bg_prefooter.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .button-reseller {
    padding: 0.5em 1em;
  }
  .role-user {
    font-size: 12px;
    font-weight: 500;
    padding: 3px 12px;
    background: var(--background);
    border-radius: 12px;
  }
  .promo-label {
    text-transform: uppercase;
    background: black;
    padding: 2px 4px;
    color: white !important;
    background: linear-gradient(180deg, #005f4d, #008a73);
    border: 2px solid #02fbbf;
    border-radius: 4px;
  }
  @media screen and (max-width: 768px) {
    .sidenav.active {
      padding-bottom: 80px;
    }
  }
  .card-reseller {
    margin: 1em;
    border-radius: 6px;
    border: var(--line);
    position: relative;
  }
  .card-reseller:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.05;
    background-image: url(/images/home/new/bg_prefooter.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .button-reseller {
    padding: 0.5em 1em;
  }
  .role-user {
    font-size: 12px;
    font-weight: 500;
    padding: 3px 12px;
    background: var(--background);
    border-radius: 12px;
  }
  .promo-label {
    text-transform: uppercase;
    background: black;
    padding: 2px 4px;
    color: white !important;
    background: linear-gradient(180deg, #005f4d, #008a73);
    border: 2px solid #02fbbf;
    border-radius: 4px;
  }
  .item {
    padding: 1.1875rem 1.25rem;
    margin: 0px;
    display: flex;
    cursor: pointer;
    font-size: 1rem;
    transition: 0.3s;
    background-color: transparent;
    color: var(--li-sidenav);
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid var(--border-sidemanu);
  }
  .item:hover {
    background: var(--background);
  }
  .menu h5 {
    padding: 1rem 1.25rem 0.5rem;
    margin: 10px 0px;
  }
`;
