import DropdownItem from './NavItemHeader';
import style from './index.style';

const NavItem = (props) => {
  const { label, to, children, promo } = props.item;
  const handleRouteChange = props.handleRouteChange;

  if (children) {
    return (
      <DropdownItem item={props.item} handleRouteChange={handleRouteChange} />
    );
  }

  return (
    <div>
      <a onClick={() => handleRouteChange(to)}>
        <div className={'item'}>
          <li>
            <span className={promo && 'font-weight-bold'}>{label}</span>
          </li>
        </div>
      </a>
      <style jsx>{style}</style>
    </div>
  );
};

export default NavItem;
