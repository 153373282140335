import Icon from 'components/utils/Icon';
import OutsideClickHandler from 'components/utils/OutsideClickHandler';
import useTranslation from 'hooks/useTranslation';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/utils/Button';
import {
  getOrders,
  setOrdersUnpaidData,
  setPaymentOrdersIds,
} from 'actions/order';
import { getFormattedDate } from '../../tools/utils';
import useResponsive from '../../hooks/useResponsive';
import { useRouterWithLang } from 'hooks/useRouterWithLang';

function Notifications() {
  const { TRANSLATIONS } = useTranslation();
  const router = useRouterWithLang();
  const { isMobile } = useResponsive();
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const ordersData = useSelector((state) => state?.ordersUnpaid);
  const user = useSelector((state) => state?.user);
  const [orders, setOrders] = useState([]);
  const [lastCheckDate, setLastCheckDate] = useState(null);
  const isLoggedIn = useSelector((s) => s.isLoggedIn);

  const handleClose = () => {
    setShow(false);
  };
  useEffect(() => {
    setOrders(ordersData?.items);
  }, [ordersData?.items]);
  const getOrdersList = useCallback(() => {
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    const q = {
      status: 0,
      virtual: {
        $ne: true,
      },
      paid: false,
      sent: false,
      type: 'P',
      user: user._id,
      createdAt: { $gte: oneWeekAgo.toISOString() },
    };
    dispatch(
      getOrders(
        {
          pageSize: 3,
          sortings: { date: -1 },
          q,
          projection: '_id status createdAt priceCost recipient',
        },
        (data) => dispatch(setOrdersUnpaidData(data)),
        false
      )
    );
  }, [dispatch, user._id]);

  useEffect(() => {
    if (isLoggedIn) {
      const fiveMinutesAgo = new Date();
      fiveMinutesAgo.setMinutes(fiveMinutesAgo.getMinutes() - 5);
      if (lastCheckDate <= fiveMinutesAgo) {
        getOrdersList();
        setLastCheckDate(new Date());
      }
    }
  }, [getOrdersList, isLoggedIn, lastCheckDate]);

  const onClickShopOrder = useCallback(
    (order) => {
      if (order?._id) {
        dispatch(setPaymentOrdersIds([order._id]));
        router.push('/update-order');
      }
    },
    [dispatch]
  );

  if (!orders?.length) return null;
  return (
    <OutsideClickHandler onOutsideClick={handleClose}>
      <div className={`mail ${!isMobile && 'relative'}`}>
        <div className={'relative'}>
          <button onClick={() => setShow((show) => !show)}>
            <Icon type="bell" style={{ fontSize: 22 }} />
          </button>
          {!!orders?.length && <span className="info-notifications"></span>}
        </div>
        <div className={`notification ${show ? 'active' : 'noactive'}`}>
          {!!orders?.length ? (
            <div className={'p-xs divider '}>
              <h6>{TRANSLATIONS.notifications.title}</h6>
              <span>{TRANSLATIONS.notifications.subtitle}</span>
            </div>
          ) : (
            <div className={'p-xs divider '}>
              <h6>{TRANSLATIONS.notifications.title}</h6>
              <span>{TRANSLATIONS.notifications.noOrderToPay}</span>
            </div>
          )}
          {!!orders?.length && (
            <>
              <div className={''}>
                {orders?.map((order, i) => (
                  <div key={order.id} className={'orders-list p-xs divider'}>
                    <div className={'d-f ai-c jc-sb'}>
                      <div className={'d-f fd-c g-5 ellipsis'}>
                        <span className={'email'}>
                          {order?.recipient?.email}
                        </span>
                        <span>
                          <strong>{TRANSLATIONS.notifications.date}: </strong>{' '}
                          {getFormattedDate(order?.createdAt)}
                        </span>
                        <span>
                          <strong>{TRANSLATIONS.notifications.total}:</strong>{' '}
                          {order?.priceCost?.total} €
                        </span>
                      </div>
                      <Button
                        label={TRANSLATIONS.notifications.payNow}
                        className={'btn-white small'}
                        onClick={() => {
                          onClickShopOrder(order);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="p-xs  g-5 jc-c d-f">
                <Button
                  className={`primary-trasparent`}
                  label={TRANSLATIONS.notifications.viewAll}
                  onClick={() => {
                    router.push('/order');
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <style jsx>{`
        .noactive {
          display: flex;
          position: absolute;
          right: 0px;
          top: 40px !important;
          transition: 0.3s;
          visibility: hidden;
          opacity: 0 !important;
        }
        .active {
          transition: 0.3s;
          opacity: 1 !important;
          right: 0px;
          top: 53px;
          transform: translateY(0px);
          z-index: 11;
        }
        .info-notifications {
          position: absolute;
          width: 10px;
          height: 10px;
          top: 6px;
          right: 6px;
          background: var(--info);
          border-radius: 100%;
        }
        .p-xs {
          padding: 16px;
        }
        .divider {
          border-bottom: var(--line);
        }
        .mail button {
          border-radius: 50px;
          height: 40px;
          width: 40px;
          background: transparent;
          cursor: pointer;
        }
        .orders-list {
          transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          -webkit-tap-highlight-color: transparent;
          background-color: rgba(145, 158, 171, 0.05);
        }
        .orders-list:hover {
          background-color: var(--li-sidenav-active);
        }
        .notification {
          display: flex;
          position: absolute;
          background: var(--background-utils);
          box-shadow: 0 10px 20px rgb(0 0 0 / 10%), 0 6px 6px rgb(0 0 0 / 15%);
          flex-direction: column;
          border-radius: var(--radius-md);
          min-width: 400px;
        }
        .notification h6 {
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: var(--primary);
        }
        .notification span {
          font-size: 14px;
          font-weight: 300;
          cursor: default;
        }
        .ellipsis span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 190px;
          font-weight: 400;
        }
        @media screen and (max-width: 425px) {
          .active {
            transition: 0.3s;
            opacity: 1 !important;
            right: 10px;
            top: 60px;
            transform: translateY(0px);
            z-index: 11;
          }
          .notification {
            display: flex;
            position: absolute;
            background: var(--background-utils);
            box-shadow: 0 10px 20px rgb(0 0 0 / 10%), 0 6px 6px rgb(0 0 0 / 15%);
            flex-direction: column;
            min-width: 16px;
            max-width: calc(100% - 32px);
            border-radius: var(--radius-md);
            width: 360px;
          }
        }
      `}</style>
    </OutsideClickHandler>
  );
}

export default Notifications;
