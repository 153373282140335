import { logAsLogout } from 'actions/admin';
import { logout } from 'actions/user';
import Icon from 'components/utils/Icon';
import useTranslation, { mergeTranslation } from 'hooks/useTranslation';
import { Link } from 'components/utils/Link';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLocaleTranslation, setTranslation } from 'actions/translations';
import SelectTheme from './SelectTheme';
import SelectLanguage from './SelectLanguage';
import useResponsive from 'hooks/useResponsive';
import { useRouterWithLang } from 'hooks/useRouterWithLang';

function UserMenuMobile({ handleMenu }) {
  const { TRANSLATIONS, locale } = useTranslation();
  const { isMobile } = useResponsive();
  const user = useSelector((state) => state.user);
  const PERMISSIONS = useSelector((state) => state.permissions);
  const dispatch = useDispatch();
  const router = useRouterWithLang();

  const linkSupport = useMemo(
    () =>
      locale === 'it_IT'
        ? 'https://bdroppy.kayako.com/it/conversation/new'
        : locale === 'fr_FR'
        ? 'https://bdroppy.kayako.com/fr/conversation/new'
        : locale === 'es_ES'
        ? 'https://bdroppy.kayako.com/es/conversation/new'
        : 'https://bdroppy.kayako.com/en-us/conversation/new',
    [locale]
  );

  const handleLogout = useCallback(() => {
    dispatch(logout());
    if (locale) {
      dispatch(
        getLocaleTranslation(locale, (translations) => {
          dispatch(setTranslation(mergeTranslation(translations || {})));
        })
      );
    }
  }, [dispatch, locale]);

  const isLoggedFromAdmin = !!user?.adminToken?.length;

  return (
    <div className="user-mobile">
      <div className={'user-menu-mobile d-f jc-sb'}>
        <div className="user-menu-mobile-title ellipsis-text d-f fd-c g-5">
          <h6>{user?.name}</h6>
          <p>{user?.email}</p>
        </div>
        <ul className="divider">
          <Link href="/profile/general" passHref>
            <li
              onClick={() => {
                handleMenu({ index: -1 });
              }}
            >
              <a>{TRANSLATIONS.profile.submenu.profile}</a>
            </li>
          </Link>
          {PERMISSIONS.IS_PRIVATE && (
            <>
              <Link href="/order" passHref>
                <li>
                  <a>{TRANSLATIONS.sidebar.orderList}</a>
                </li>
              </Link>
              <Link href="/order/return" passHref>
                <li>
                  <a>{TRANSLATIONS.sidebar.return}</a>
                </li>
              </Link>
            </>
          )}
          <Link href="/profile/address" passHref>
            <li
              onClick={() => {
                handleMenu({ index: -1 });
              }}
            >
              <a>{TRANSLATIONS.profile.submenu.address}</a>
            </li>
          </Link>{' '}
          <Link href="/profile/billing" passHref>
            <li
              onClick={() => {
                handleMenu({ index: -1 });
              }}
            >
              <a>{TRANSLATIONS.profile.submenu.billing}</a>
            </li>
          </Link>{' '}
          <Link href="/profile/ecredit" passHref>
            <li
              onClick={() => {
                handleMenu({ index: -1 });
              }}
            >
              <a>{TRANSLATIONS.profile.submenu.ecredit}</a>
            </li>
          </Link>
          {PERMISSIONS.IS_PRIVATE && (
            <Link href="/profile/affiliation" passHref>
              <li
                onClick={() => {
                  handleMenu({ index: -1 });
                }}
              >
                <a>{TRANSLATIONS.profile.submenu.referral}</a>
              </li>
            </Link>
          )}
          {!PERMISSIONS.IS_FREE_USER && (
            <Link href={linkSupport} passHref>
              <a target="_blank" rel="noreferrer">
                <li>
                  <a>{TRANSLATIONS.profile.submenu.technicalSupport}</a>
                </li>
              </a>
            </Link>
          )}
        </ul>
        <div className="user-menu-mobile-mode">
          <div className="title">{TRANSLATIONS.profile.submenu.selectLang}</div>
          <div className="d-f g-20 ai-c mt-md">
            <SelectLanguage inline />
          </div>
          <div className="title mt-xl">{TRANSLATIONS.profile.submenu.mode}</div>
          <div className="d-f g-20 ai-c jc-sb">
            <SelectTheme />
          </div>
        </div>
        <div className="f-1" />
        <ul className="logout">
          <li onClick={handleLogout} className="d-f jc-sb ai-c">
            <a>{TRANSLATIONS.profile.submenu.logout}</a>
            <Icon type="log-out" />
          </li>
          {isLoggedFromAdmin && (
            <li
              onClick={() => {
                dispatch(logAsLogout());
                router.push('/admin/users');
              }}
            >
              <a>{TRANSLATIONS.profile.submenu.backAdmin}</a>
            </li>
          )}
        </ul>
      </div>
      <style jsx>{`
        .user-mobile {
          height: 100%;
        }
        .divider {
          border-bottom: solid thin #ccc4;
        }
        .logout {
          border-top: solid thin #ccc4;
        }
        .logout li a {
          font-size: 16px;
          font-weight: 400;
        }
        .user-mobile button {
          border-radius: 50px;
          height: 40px;
          width: 40px;
          background: var(--foreground);
          color: var(--background-utils);
          cursor: pointer;
        }
        .user-menu-mobile {
          display: flex;
          flex-direction: column;
          border-radius: var(--radius-md);
          height: 100%;
        }
        .user-menu-mobile-title {
          padding: 24px 16px 24px;
          border-bottom: solid thin #ccc4;
        }
        .user-menu-mobile-mode {
          padding: 10px 16px;
        }
        .user-menu-mobile-mode span {
          font-size: 14px;
          font-weight: 500;
          cursor: default;
        }
        .user-menu-mobile-title h6 {
          margin: 0;
          font-size: 15px;
          font-weight: 600;
          text-transform: uppercase;
        }
        .user-menu-mobile-title p {
          font-size: 14px;
        }
        .user-menu-mobile ul {
          list-style-type: none;
          text-align: left;
          padding: 8px 0px 8px;
          margin: 0;
        }
        .user-menu-mobile ul li {
          padding: 12px 16px;
          display: flex;
          cursor: pointer;
          user-select: none;
          vertical-align: middle;
          appearance: none;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          transition: background-color 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          -webkit-tap-highlight-color: transparent;
          outline: 0px;
          background-color: transparent;
          -webkit-tap-highlight-color: transparent;
        }
        .title {
          font-weight: 500;
          font-size: 11px;
          -webkit-letter-spacing: 2px;
          -moz-letter-spacing: 2px;
          -ms-letter-spacing: 2px;
          letter-spacing: 2px;
          padding: 15px 0px 9px;
          text-transform: uppercase;
          color: var(--title-sidenav);
        }
        .user-menu-mobile ul li::before {
          top: 0px;
          right: 0px;
          width: 3px;
          display: block;
          bottom: 0px;
          content: '';
          display: none;
          position: absolute;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          background-color: rgb(0, 171, 85);
        }
        .user-menu-mobile ul li span {
          font-size: 18px;
        }
        .user-menu-mobile ul li a {
        }
        .user-menu-mobile ul li svg {
          width: 28px;
          height: 28px;
        }
        .user-menu-mobile ul li:hover {
          background: rgba(145, 158, 171, 0.08);
        }
        .ellipsis-text h6,
        .ellipsis-text p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 190px;
        }
      `}</style>
    </div>
  );
}

export default UserMenuMobile;
