import Modal from '../utils/Modal';
import useTranslation from '../../hooks/useTranslation';
import React, { useEffect, useState } from 'react';
import Icon from '../utils/Icon';
import { useDispatch, useSelector } from 'react-redux';
import { showNewSignupPopupAction } from 'actions/user';
import Button from '../utils/Button';
import { useRouter } from 'next/router';

function PopupNewSignup() {
  const { TRANSLATIONS } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const router = useRouter();
  const PERMISSIONS = useSelector((state) => state.permissions);

  useEffect(() => {
    const handler = setTimeout(() => {
      setModalOpen(true);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, []);

  const onCloseModal = () => {
    setModalOpen(false);
    dispatch(showNewSignupPopupAction(false));
  };

  if (
    !PERMISSIONS.IS_FREE_USER ||
    router.pathname.includes('pricing') ||
    router.pathname.includes('payment') ||
    router.pathname.includes('channel')
  )
    return null;

  return (
    <>
      <Modal
        openModal={modalOpen}
        showHeader={false}
        modalContentWrapperClassname="popup-newsletter-wrapper"
        modalContentClassname="popup-newsletter-content"
        onClose={onCloseModal}
      >
        <div className="popup-newsletter-container">
          <div className="container">
            <div className="title-container">
              <p className="title mb-xl text-bold">
                {TRANSLATIONS.popupNewSignup.title}
              </p>
              <p className="subtitle mb-xxl">
                {TRANSLATIONS.popupNewSignup.t1}
              </p>
              <p className="subtitle mb-md text-bold">
                {TRANSLATIONS.popupNewSignup.t2}
              </p>
              <p className="subtitle mb-md">{TRANSLATIONS.popupNewSignup.t3}</p>
              <p className="subtitle mb-md text-bold">
                {TRANSLATIONS.popupNewSignup.t4}
              </p>
            </div>
            <Button
              label={TRANSLATIONS.popupNewSignup.cta}
              onClick={onCloseModal}
              className="large button primary"
            />
          </div>
          <div className="image-container">
            <img src="/popup-newsletter.png" alt="Bdroppy" />
          </div>
          <div className="close-icon" onClick={onCloseModal}>
            <Icon type="x" />
          </div>
        </div>
      </Modal>
      <style jsx>{`
        .text-bold {
          font-weight: bold;
        }
        .container {
          padding: 51px 35px;
          border-radius: 24px;
          align-items: center;
          display: flex;
          flex-direction: column;
          gap: 23px;
          justify-content: center;
          position: relative;
          flex: 2;
        }
        .title-container {
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .title {
          font-size: 26px;
          line-height: 30px;
          width: 80%;
        }
        .subtitle {
          font-size: 20px;
          line-height: 20px;
        }
        .bottom-text {
          font-size: 14px;
          line-height: 20px;
        }
        .form {
          font-weight: bold;
        }
        .close-icon {
          position: absolute;
          top: 16px;
          right: 16px;
          height: 42px;
          width: 42px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #d4d4d4;
          border-radius: 34px;
          box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.13);
          cursor: pointer;
          background: white;
        }
        .popup-newsletter-container {
          display: flex;
          position: relative;
          max-width: 940px;
        }
        .image-container {
          flex: 2;
          display: none;
        }
        .form-container {
          width: 100%;
          max-width: 350px;
        }
        .link {
          color: var(--primary);
          font-weight: 600;
          cursor: pointer;
        }
        @media screen and (min-width: 940px) {
          .image-container {
            display: block;
            min-width: 430px;
          }
          .title {
            width: 100%;
          }
          .subtitle {
            width: 70%;
          }
          .form-container {
            padding: 0 35px;
          }
        }
      `}</style>
    </>
  );
}

export default PopupNewSignup;
