import { getUser } from 'actions/user';
import message from 'components/utils/message';
import request from 'tools/request';
import {
  getAdminToken,
  getToken,
  mergeDeep,
  removeAdminToken,
  setAdminToken,
  setToken,
} from 'tools/utils';

export const initParams = {
  pageNumber: 1,
  pageSize: 10,
  sortings: { subscriptionDate: -1 },
};
export function getUsers(params, onSuccess = () => {}, onError = () => {}) {
  return (dispatch) => {
    request(`/api/user/filter`, {
      params: mergeDeep({ ...initParams }, params),
      method: 'GET',
    })
      .then((response) => {
        onSuccess(response.data);
      })
      .catch((err) => {
        onError();
      });
  };
}

export function logAs(user, onSuccess = () => {}, onError = () => {}) {
  return (dispatch) => {
    request(`/api/auth/logas/${user}/short`, {
      method: 'GET',
    })
      .then((response) => {
        const currentToken = getToken();
        setToken(response.data.token);
        setAdminToken(currentToken);
        dispatch(
          getUser(false, () => {
            message.error(`Can\'t log as user ${user}.`);
            dispatch(logAsLogout());
          })
        );

        onSuccess();
      })
      .catch((err) => {
        message.error('Username or password is incorrect.');
        onError();
      })
      .then(() => {});
  };
}

export function logAsLogout() {
  return (dispatch) => {
    const adminToken = getAdminToken();
    if (adminToken?.length) {
      setToken(adminToken);
      removeAdminToken();
    }
    dispatch(getUser(false));
  };
}
export function getStatistics(onSuccess = () => {}, onError = () => {}) {
  return (dispatch) => {
    request(`/api/bill/statistics`, {
      method: 'POST',
      data: {
        startTime: '2021-01-01',
        endTime: '2022-01-01',
      },
    })
      .then((response) => {
        onSuccess();
      })
      .catch((err) => {
        message.error("Can't load statistics.");
        onError();
      })
      .then(() => {});
  };
}

export function updateUser(userId, data, onSuccess = () => {}) {
  return () => {
    request(`/api/user/${userId}`, {
      method: 'PUT',
      data: data,
    })
      .then(onSuccess)
      .catch((err) => console.log(err));
  };
}
export function downloadBills({
  startTime,
  endTime,
  invoiceType,
  invoiceClass,
  creditNote,
} = {}) {
  return (dispatch) => {
    request(
      `/api/invoice/type/${invoiceType}/class/${invoiceClass}/credit_note/${creditNote}/file/csv/export/`,
      {
        params: { startTime, endTime },
        responseType: 'blob',
      }
    )
      .then((response) => {
        const filename =
          response?.headers
            ?.get('Content-Disposition')
            ?.split('filename=')?.[1]
            .replace(/"/g, '') || 'Bills.csv';
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => console.log('Error', err));
  };
}
