import { logAsLogout } from 'actions/admin';
import { logout } from 'actions/user';
import Icon from 'components/utils/Icon';
import OutsideClickHandler from 'components/utils/OutsideClickHandler';
import useTranslation, { mergeTranslation } from 'hooks/useTranslation';
import { Link } from 'components/utils/Link';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLocaleTranslation, setTranslation } from 'actions/translations';
import SelectTheme from './SelectTheme';
import SelectLanguage from './SelectLanguage';
import useResponsive from 'hooks/useResponsive';
import Button from '../utils/Button';
import { useRouterWithLang } from 'hooks/useRouterWithLang';

function UserMenu({ handleMenu = () => {} }) {
  const { TRANSLATIONS, locale } = useTranslation();
  const { isMobile } = useResponsive();
  const isLoggedIn = useSelector((s) => s.isLoggedIn);
  const [show, setShow] = useState(false);
  const user = useSelector((state) => state.user);
  const PERMISSIONS = useSelector((state) => state.permissions);
  const dispatch = useDispatch();
  const router = useRouterWithLang();

  const handleClose = () => {
    setShow(false);
  };

  const linkSupport = useMemo(
    () =>
      locale === 'it_IT'
        ? 'https://bdroppy.kayako.com/it/conversation/new'
        : locale === 'fr_FR'
        ? 'https://bdroppy.kayako.com/fr/conversation/new'
        : locale === 'es_ES'
        ? 'https://bdroppy.kayako.com/es/conversation/new'
        : 'https://bdroppy.kayako.com/en-us/conversation/new',
    [locale]
  );

  const handleLogout = useCallback(() => {
    dispatch(logout());
    if (locale) {
      dispatch(
        getLocaleTranslation(locale, (translations) => {
          dispatch(setTranslation(mergeTranslation(translations || {})));
        })
      );
    }
  }, [dispatch, locale]);

  const isLoggedFromAdmin = !!user?.adminToken?.length;

  return (
    <OutsideClickHandler onOutsideClick={handleClose}>
      <div className="user relative">
        <button onClick={() => setShow((show) => !show)}>
          <Icon type="user" style={{ fontSize: 22 }} />
        </button>
        {isLoggedIn && (
          <div className={show ? 'user-menu active' : 'user-menu noactive'}>
            <div className="user-menu-title">
              <span className="arrow"></span>
              <h6>{user?.name}</h6>
              <p>{user?.email}</p>
            </div>
            <div className="user-menu-mode">
              <div className="d-f g-20 ai-c">
                <span>{TRANSLATIONS.profile.submenu.mode}</span>
                <SelectTheme />
              </div>
              {isMobile && (
                <div className="d-f g-20 ai-c mt-md">
                  <span>{TRANSLATIONS.profile.submenu.selectLang}</span>
                  <SelectLanguage />
                </div>
              )}
            </div>
            <ul>
              <Link href="/profile/general" passHref>
                <li>
                  <a>{TRANSLATIONS.profile.submenu.profile}</a>
                </li>
              </Link>
              {PERMISSIONS.IS_PRIVATE && (
                <>
                  <Link href="/order" passHref>
                    <li>
                      <a>{TRANSLATIONS.sidebar.orderList}</a>
                    </li>
                  </Link>
                  <Link href="/order/return" passHref>
                    <li>
                      <a>{TRANSLATIONS.sidebar.return}</a>
                    </li>
                  </Link>
                </>
              )}
              {!PERMISSIONS.IS_PRIVATE && false && (
                <Link href={linkSupport} passHref>
                  <a target="_blank" rel="noreferrer">
                    <li>
                      <a>{TRANSLATIONS.profile.submenu.technicalSupport}</a>
                    </li>
                  </a>
                </Link>
              )}
              <li onClick={handleLogout}>
                <a>{TRANSLATIONS.profile.submenu.logout}</a>
              </li>
              {isLoggedFromAdmin && (
                <li
                  onClick={() => {
                    dispatch(logAsLogout());
                    router.push('/admin/users');
                  }}
                >
                  <a>{TRANSLATIONS.profile.submenu.backAdmin}</a>
                </li>
              )}
            </ul>
          </div>
        )}
        {!isLoggedIn && (
          <div className={show ? 'user-menu active' : 'user-menu noactive'}>
            <div className="user-menu-title d-f fd-c g-5">
              <Button
                className={'action medium '}
                label={TRANSLATIONS.auth.loginSidebar}
                onClick={() => {
                  router.push('/login');
                }}
              />
              <Button
                className={'accent medium '}
                label={TRANSLATIONS.auth.register}
                onClick={() => {
                  router.push('/register');
                }}
              />
            </div>
            <ul></ul>
          </div>
        )}
      </div>
      <style jsx>{`
        .noactive {
          display: flex;
          position: absolute;
          right: 8px;
          top: 60px !important;
          transition:.3s; 
          visibility: hidden;
          opacity: 0 !important;
        }
        .active {
          transition:.3s; 
          opacity: 1 !important;
          right: 8px;
          top: 50px;
          transform: translateY(0px);
          z-index:11;
        }
        .user button {
          border-radius: 50px;
          height: 40px;
          width: 40px;
          background: var(--foreground);
          color: var(--background-utils);
          cursor: pointer;
        }
        .user-menu {
          display: flex;
          position: absolute;
          background: var(--white);
          box-shadow: 0 10px 20px rgb(0 0 0 / 10%), 0 6px 6px rgb(0 0 0 / 15%);
          backdrop-filter: blur(40px) brightness(80%);
          flex-direction: column;
          border-radius: var(--radius-md);
        }
        .user-menu-title {
          padding: 10px 16px;
        }
        .user-menu-mode {
          padding: 10px 16px;
          border-bottom: solid thin #ccc4;
        }
        .user-menu-mode span{
          font-size: 14px;
          color: var(--grey-dark);
          font-weight: 500;
          cursor:default;
        }
        .user-menu-title h6 {
          margin: 0;
          font-size: 11px;
          font-weight: 700;
          text-transform: uppercase;
          color: var(--grey-dark);
        }
        .user-menu-title p {
          margin: 0;
          font-size: 13px;
          color: var(--grey-dark);
          margin-top: var(--margin-sm);
        }
        .user-menu ul {
          list-style-type: none;
          text-align: left;
          padding: 0;
          margin: 0;
        }
        .user-menu ul li {
          padding: 12px 16px;
          display: flex;
          cursor: pointer;
          user-select: none;
          vertical-align: middle;
          appearance: none;
          align-items: center;
          font-size: 14px;
          color: var(--grey-dark);
          font-weight: 500;
          transition: background-color 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          -webkit-tap-highlight-color: transparent;
          outline: 0px;
          background-color: transparent;
          -webkit-tap-highlight-color: transparent;
        }
        .user-menu ul li:not(:last-child) {
        }
        .user-menu ul li::before {
          top: 0px;
          right: 0px;
          width: 3px;
          display: block;
          bottom: 0px;
          content: '';
          display: none;
          position: absolute;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          background-color: rgb(0, 171, 85);
        }
        .user-menu ul li span {
          font-size: 18px;
        }
        .user-menu ul li a {
        }
        .user-menu ul li svg {
          width: 28px;
          height: 28px;
        }
        .user-menu ul li:hover {
          background: rgba(145, 158, 171, 0.08);
        }
        .arrow {
          top: -6px;
          z-index: 1;
          background: var(--white);
          width: 12px;
          right: 22px;
          height: 12px;
          content: '';
          position: absolute;
          border-radius: 0px 0px 2px;
          transform: rotate(-135deg);
          border-right: 1px solid rgba(145, 158, 171, 0.12);
          border-bottom: 1px solid rgba(145, 158, 171, 0.12);
        }
        @media only screen and (min-width: 46.875em) {
          .user-menu {
            display: flex;
            position: absolute;
            padding: 0.5em;
            right: 0px;
            top: 65px;
            background: var(--white);
            box-shadow: box-shadow: 0 10px 20px rgb(0 0 0 / 10%), 0 6px 6px rgb(0 0 0 / 15%);
            z-index:11;
            backdrop-filter: blur(40px) brightness(80%);
            opacity: 1;
            flex-direction: column;
            border-radius: var(--radius-md);
          }
          .arrow {
            right: 15px;
          }
        }
      `}</style>
    </OutsideClickHandler>
  );
}

export default UserMenu;
