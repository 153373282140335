import css from 'styled-jsx/css';

export default css`
  .dropdown-item {
    border-bottom: 1px solid var(--border-sidemanu);
  }
  .item {
    margin: 0px;
    position: relative;
    padding: 1.1875rem 1.25rem;
    display: flex;
    cursor: pointer;
    user-select: none;
    font-size: 1rem;
    transition: 0.3s;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
    color: var(--li-sidenav);
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .item-children {
    padding: 10px 1.25rem;
    display: flex;
    position: relative;
    cursor: pointer;
    user-select: none;
    font-size: 1rem;
    font-weight: 400;
    transition: 0.3s;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
    color: var(--li-sidenav);
    display: flex;
    margin-left: 10px;
    justify-content: space-between;
    width: 100%;
  }
  .icon-chevron-down.active {
    font-size: 18px;
    right: 16px;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-weight: 500;
  }
  .icon-chevron-down {
    font-size: 18px;
    right: 16px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .active {
    font-weight: 700;
  }
  .dropdown-children .dropdown-item {
    border: none;
    margin-left: 10px;
  }
  .dropdown-children .item {
    border: none;
    padding: 10px 1.25rem;
  }
  .dropdown-item .item:hover {
    background: var(--background);
  }
  .item-children a:hover :after,
  .dropdown-item .item a.active :after {
    width: 100%;
    left: 0;
  }
  .item-children a,
  .dropdown-item .item a {
    position: relative;
  }
  .item-children a:after,
  .dropdown-item .item a:after {
    background: none repeat scroll 0 0 transparent;
    bottom: -6px;
    content: '';
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: var(--foreground);
    -webkit-transition: width 0.3s ease 0s, left 0.3s ease 0s;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
`;
