import css from 'styled-jsx/css';

export default css`
  :global(.accordion-container) {
    width: 100%;
    /* border: 1px solid #d9d9d9; */
    border-radius: 2px;
    padding: 12px 6px;
    margin-top: 12px;
    cursor: pointer;
    transition: 0.3s;
    position: relative;
  }

  :global(.accordion-head) {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--primary);
  }

  :global(.accordion-text) {
    font-size: 14px;
    font-weight: 400;
    margin: auto;
  }

  :global(.accordion-icon) {
    transition: 0.3s ease;
    font-size: 25px;
    position: absolute;
  }

  :global(.accordion-icon-open) {
    transform: rotate(90deg);
  }

  :global(.accordion-content) {
    height: 0;
    transition: height 0.3s ease-out;
    overflow: hidden;
  }
`;
