import SelectLanguage from 'components/generic/SelectLanguage';
import UserMenu from 'components/generic/UserMenu';
import UserMenuMobile from 'components/generic/UserMenuMobile';
import { DARK_THEME_NAME } from 'tools/constants';
import DrawerUser from 'components/utils/DrawerUser';
import Icon from 'components/utils/Icon';
import useResponsive from 'hooks/useResponsive';
import { useTheme } from 'next-themes';
import { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import Notifications from '../../components/generic/Notifications';
import { Link } from 'components/utils/Link';
import useTranslation from '../../hooks/useTranslation';
import SubHeader from './SubHeader';
import CartModal from '../../components/cart/cart-modal';
import Modal from '../../components/utils/Modal';
import Button from '../../components/utils/Button';
import style from './header.style';
import { useRouterWithLang } from 'hooks/useRouterWithLang';

function HeaderFree({ menuOpenHandler }) {
  const { TRANSLATIONS, translationsParameters } = useTranslation();
  const { isMobile, isTablet } = useResponsive();
  const isSmallDevice = isMobile || isTablet;
  const router = useRouterWithLang();
  const userCatalog = useSelector((state) => state?.user?.userCatalogs)?.[0];
  const PERMISSIONS = useSelector((state) => state.permissions);
  const { resolvedTheme } = useTheme();
  const userEcredit = useSelector((state) => state.user?.ecredit) || '';
  const [activeMenu, setActiveMenu] = useState({ index: -1 });
  const handleMenu = useCallback(({ index }) => {
    setActiveMenu({ index });
  }, []);
  const UI = useSelector((state) => state?.UI) || [];
  const message = UI?.show_confirmation_email_message;
  const isLoggedIn = useSelector((s) => s.isLoggedIn);

  useEffect(() => {
    if (activeMenu.index === 1) {
      document.body.classList.add('overlay-body');
    } else document.body.classList.remove('overlay-body');
  }, [activeMenu]);

  const [modalOpen, setModalOpen] = useState({ type: null, index: -1 });

  return (
    <>
      <Modal
        openModal={
          modalOpen?.type && (modalOpen?.index >= 0 || modalOpen?.index.length)
        }
        onClose={() => setModalOpen({ type: null, index: -1 })}
        width={isMobile ? '100%' : '400px'}
        scroll
        canClose
      >
        <div>
          <h3 className={'ta-c'}>{TRANSLATIONS.header?.wishlist?.title}</h3>

          <div className="mt-md mb-md ta-c">
            <p>{TRANSLATIONS.header?.wishlist?.content}</p>
          </div>

          <div className={'d-f fd-c g-10 ta-c jc-c mt-xl'}>
            <Button
              label={TRANSLATIONS?.header?.wishlist?.CTA}
              className={'large primary'}
              onClick={() => {
                router.push('/register');
              }}
            />
            <Link href={'/login'}>
              <div className={'d-f ai-c g-5 jc-c ta-c mb-xl'}>
                {translationsParameters(TRANSLATIONS.shop.account, [
                  <span key={'account'} className={'account'}>
                    {TRANSLATIONS.shop.login}
                  </span>,
                ])}
              </div>
            </Link>
          </div>
        </div>
      </Modal>
      <div
        className={`header__free ${
          isMobile && activeMenu.index === 1 && 'zindex-custom'
        }`}
      >
        {isSmallDevice && isLoggedIn && (
          <DrawerUser
            visible={activeMenu?.index !== -1}
            onCloseDrawer={() => {
              handleMenu({ index: -1 });
            }}
          >
            <UserMenuMobile handleMenu={handleMenu} activeMenu={activeMenu} />
          </DrawerUser>
        )}
        <div className={`header__menu`}>
          <div className={`header__menu-wrap ai-c d-f`}>
            <div className={'header__menu-wrap-left'}>
              <div
                className={'header__menu-wrap-left-menu'}
                onClick={menuOpenHandler}
              >
                <Icon type="menu" />
                {!isSmallDevice && <p>{TRANSLATIONS.header.menu}</p>}
              </div>
              {isSmallDevice && (
                <Link href={'/'}>
                  <div className="header__menu-wrap-center-logo ai-c d-f" />
                </Link>
              )}
            </div>
            {!isSmallDevice && (
              <div className={'header__menu-wrap-center'}>
                <Link href={'/'}>
                  <div className="header__menu-wrap-center-logo ai-c d-f" />
                </Link>
              </div>
            )}
            <div className={'header__menu-wrap-right'}>
              <div
                className={`header__menu-wrap-right-box d-f ai-c ${
                  isMobile ? 'g-2' : 'g-10'
                }`}
              >
                {isLoggedIn && (
                  <div
                    onClick={() => {
                      router.push(`/channel/${userCatalog?._id}/wishlist`);
                    }}
                  >
                    <Icon
                      type="heart"
                      style={{ fontSize: isMobile ? 20 : 22, margin: 'auto' }}
                    />
                  </div>
                )}
                {!isSmallDevice && userEcredit && (
                  <div
                    onClick={() => {
                      router.push('/profile/ecredit');
                    }}
                  >
                    {resolvedTheme === DARK_THEME_NAME ? (
                      <img
                        src="/images/Icon-ecredit-dark.svg"
                        alt={'Icon-ecredit-dark'}
                      />
                    ) : (
                      <img
                        src="/images/ecredit/Icon-ecredit.svg"
                        alt={'Icon-ecredit'}
                      />
                    )}
                  </div>
                )}
                <Notifications />
                <CartModal resolvedTheme={resolvedTheme} />
                {!isMobile && <SelectLanguage />}
                {isMobile && !isLoggedIn && <SelectLanguage />}
                {!isSmallDevice && isLoggedIn && <UserMenu />}
                {isSmallDevice && isLoggedIn && (
                  <div className="user">
                    <button
                      className={'action-trasparent'}
                      onClick={() => {
                        handleMenu({ index: 1 });
                      }}
                    >
                      <Icon
                        type="user"
                        style={{ fontSize: isMobile ? 20 : 22, margin: 'auto' }}
                      />
                    </button>
                  </div>
                )}
                {!isLoggedIn && (
                  <div className="user">
                    <button
                      onClick={() => {
                        router.push('/register');
                      }}
                    >
                      <Icon
                        type="user"
                        style={{ fontSize: isMobile ? 20 : 22, margin: 'auto' }}
                      />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {!isMobile && <SubHeader />}
        <style jsx>{style}</style>
      </div>
    </>
  );
}

export default HeaderFree;
