import Icon from 'components/utils/Icon';
import useResponsive from 'hooks/useResponsive';
import useTranslation from 'hooks/useTranslation';
import style from './footer.style';
import Link from 'next/link';
import Accordion from '../../components/utils/Accordion';
import React, { useMemo } from 'react';
import { InlineIcon } from '@iconify/react';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Pagination, Thumbs } from 'swiper';

function Footer() {
  const { isMobile } = useResponsive();
  const isLoggedIn = useSelector((s) => s.isLoggedIn);
  const { TRANSLATIONS, locale } = useTranslation();
  const user = useSelector((state) => state.user);

  const reviews = [
    {
      title: TRANSLATIONS.products.detail.reviews.title_1,
      review: TRANSLATIONS.products.detail.reviews.review_1,
    },
    {
      title: TRANSLATIONS.products.detail.reviews.title_2,
      review: TRANSLATIONS.products.detail.reviews.review_2,
    },
    {
      title: TRANSLATIONS.products.detail.reviews.title_3,
      review: TRANSLATIONS.products.detail.reviews.review_3,
    },
    {
      title: TRANSLATIONS.products.detail.reviews.title_4,
      review: TRANSLATIONS.products.detail.reviews.review_4,
    },
  ];

  const linkContattaci = useMemo(
    () =>
      locale === 'it_IT'
        ? 'https://bdroppy.kayako.com/it/conversation/new'
        : locale === 'fr_FR'
        ? 'https://bdroppy.kayako.com/fr/conversation/new'
        : locale === 'es_ES'
        ? 'https://bdroppy.kayako.com/es/conversation/new'
        : 'https://bdroppy.kayako.com/en-us/conversation/new',
    [locale]
  );

  const linkfaq = useMemo(
    () =>
      locale === 'it_IT'
        ? 'https://bdroppy.kayako.com/it'
        : locale === 'fr_FR'
        ? 'https://bdroppy.kayako.com/fr'
        : locale === 'es_ES'
        ? 'https://bdroppy.kayako.com/es'
        : 'https://bdroppy.kayako.com/en-us',
    [locale]
  );
  const linkResi = useMemo(
    () =>
      locale === 'it_IT'
        ? 'https://bdroppy.kayako.com/it/article/211-come-posso-effettuare-una-richiesta-di-reso'
        : locale === 'fr_FR'
        ? 'https://bdroppy.kayako.com/fr/article/211-comment-puis-je-faire-une-demande-de-retour'
        : locale === 'es_ES'
        ? 'https://bdroppy.kayako.com/es/article/211-que-pasos-debo-seguir-para-hacer-una-solicitud-de-devolucion'
        : 'https://bdroppy.kayako.com/en-us/article/211-how-can-i-make-a-return-request',
    [locale]
  );
  return (
    <div className={`footer`}>
      <div
        id="reviews"
        className="detail-background reviews-background-padding bg-reviews"
      >
        <div className="swiper-content">
          <div className="d-f fd-r jc-c ai-c ta-c padding-text mb-xl">
            <h5>{TRANSLATIONS.products.detail.clients}</h5>
          </div>
          <Swiper
            spaceBetween={!isMobile && 6}
            slidesPerGroup={1}
            className="swiper-reviews"
            slidesPerView={'auto'}
            loopFillGroupWithBlank={true}
            freeMode={true}
            navigation={!isMobile}
            watchSlidesProgress={true}
            pagination={{ type: isMobile && 'progressbar' }}
            modules={[FreeMode, Navigation, Thumbs, Pagination]}
            grabCursor={true}
          >
            {reviews?.map((review) => (
              <SwiperSlide key={review?.id}>
                <div className="swiper-review">
                  <div className={'rating d-f '}>
                    <InlineIcon icon="ic:round-star" />
                    <InlineIcon icon="ic:round-star" />
                    <InlineIcon icon="ic:round-star" />
                    <InlineIcon icon="ic:round-star" />
                    <InlineIcon icon="ic:round-star" />
                  </div>
                  <span className="review-title">{review?.title}</span>
                  <span className="review">{review?.review}</span>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      {!isMobile && (
        <div className={'footer__menu'}>
          <div className={'footer__menu-box'}>
            <h6>{TRANSLATIONS.footer.company}</h6>
            <p>
              <Link href={'/about-us'}>{TRANSLATIONS.footer.about}</Link>
            </p>
          </div>
          <div className={'footer__menu-box'}>
            <h6>{TRANSLATIONS.footer.resources}</h6>
            <p>
              <Link href={'/shipping-fees'}>
                {TRANSLATIONS.footer.shippingFees}
              </Link>
            </p>
            <a
              href="https://drive.google.com/drive/folders/100h1EQf078FN7Zehn4RABuE5gx-Kp_Xl"
              target="_blank"
              rel="noreferrer"
            >
              <p>{TRANSLATIONS.footer.images}</p>
            </a>
            <p>
              <Link href={'/pricing'}>{TRANSLATIONS.footer.pricing}</Link>
            </p>
          </div>
          <div className={'footer__menu-box'}>
            <h6>{TRANSLATIONS.footer.partners}</h6>
            <p>
              <Link href={'/reseller-area'}>
                {TRANSLATIONS.footer.reseller}
              </Link>
            </p>
            <p>
              <Link href={'/be-the-only-one'}>
                {TRANSLATIONS.footer.onlyOne}
              </Link>
            </p>
            <p>
              <Link href={'/influencer-program'}>
                {TRANSLATIONS.footer.influencer}
              </Link>
            </p>
            <p>
              <Link href={'/cashback'}>{TRANSLATIONS.footer.cashback}</Link>
            </p>
          </div>
          <div className={'footer__menu-box'}>
            <h6>{TRANSLATIONS.footer.help}</h6>
            <a href={linkfaq} target={'_blank'} rel={'noreferrer'}>
              {' '}
              <p>{TRANSLATIONS.footer.faq}</p>
            </a>
            <a href={linkContattaci} target={'_blank'} rel={'noreferrer'}>
              <p>{TRANSLATIONS.footer.contactUs}</p>
            </a>
            <a href={linkResi} target={'_blank'} rel={'noreferrer'}>
              <p>{TRANSLATIONS.footer.howToReturn}</p>
            </a>
            {(locale === 'it_IT' || locale === 'en_US') &&
              (user?.roles?.[0].name === 'free' || !isLoggedIn) && (
                <a
                  href={
                    'https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ1idAAZZiew3oSGW6zAekaiSZFGqs-vyDw-KmTGJUdRCgHVqDuTbF1JDLzTyLjeXFAOcA--BcSR'
                  }
                  target={'_blank'}
                  rel={'noreferrer noopener'}
                >
                  <p>{TRANSLATIONS.footer.bookACall}</p>
                </a>
              )}
          </div>
        </div>
      )}
      {isMobile && (
        <div className={'footer__menu d-f fd-c'}>
          <Accordion label={TRANSLATIONS.footer.company}>
            <div className={'footer__accordion'}>
              <p>
                <Link href={'/about-us'}>{TRANSLATIONS.footer.about}</Link>
              </p>
            </div>
          </Accordion>
          <Accordion label={TRANSLATIONS.footer.resources}>
            <div className={'footer__accordion'}>
              <Link href={'/shipping-fees'}>
                <p>{TRANSLATIONS.footer.shippingFees}</p>
              </Link>
              <a
                href="https://drive.google.com/drive/folders/100h1EQf078FN7Zehn4RABuE5gx-Kp_Xl"
                target="_blank"
                rel="noreferrer"
              >
                <p>{TRANSLATIONS.footer.images}</p>
              </a>
              <Link href={'/pricing'}>
                <p>{TRANSLATIONS.footer.pricing}</p>
              </Link>
            </div>
          </Accordion>
          <Accordion label={TRANSLATIONS.footer.partners}>
            <div className={'footer__accordion'}>
              <Link href={'/reseller-area'}>
                {TRANSLATIONS.footer.reseller}
              </Link>
              <Link href={'/be-the-only-one'}>
                {TRANSLATIONS.footer.onlyOne}
              </Link>

              <Link href={'/influencer-program'}>
                {TRANSLATIONS.footer.influencer}
              </Link>
              <Link href={'/cashback'}>{TRANSLATIONS.footer.cashback}</Link>
            </div>
          </Accordion>
          <Accordion label={TRANSLATIONS.footer.help}>
            <div className={'footer__accordion'}>
              <a href={linkfaq} target={'_blank'} rel={'noreferrer'}>
                <p>{TRANSLATIONS.footer.faq}</p>
              </a>
              <a href={linkContattaci} target={'_blank'} rel={'noreferrer'}>
                <p>{TRANSLATIONS.footer.contactUs}</p>
              </a>
              <a href={linkResi} target={'_blank'} rel={'noreferrer'}>
                <p>{TRANSLATIONS.footer.howToReturn}</p>
              </a>
            </div>
          </Accordion>
        </div>
      )}
      <div className={`footer__info ${isMobile && 'd-f fd-c g-20'}`}>
        <div
          className={`footer__menu-box ${
            isMobile ? 'w-100 ai-fs' : 'jc-c ai-c'
          }`}
        >
          <h6>{TRANSLATIONS.footer.paymentMethod}</h6>
          <div className={'payment d-f g-10 fw-w'}>
            <img src={'/images/footer/visa-footer.png'} alt={'visa-footer'} />
            <img
              src={'/images/footer/paypal_footer.png'}
              alt={'paypal_footer'}
            />
            <img src={'/images/footer/mc_symbol.svg'} alt={'mc_symbol'} />
          </div>
        </div>
        <div
          className={`footer__menu-box ${
            isMobile ? 'w-100 ai-fs' : 'jc-c ai-c'
          }`}
        >
          <h6>{TRANSLATIONS.footer.shipping}</h6>
          <div className={'payment d-f g-10'}>
            <img src={'/images/footer/dhl_footer.png'} alt={'dhl_footer'} />
            <img src={'/images/footer/brt.svg'} alt={'brt'} />
          </div>
        </div>
      </div>
      <div className={`footer__social`}>
        <a
          href="https://www.facebook.com/bdroppy.official"
          target="_blank"
          rel="noreferrer"
        >
          <Icon type="facebook" style={{ fontSize: 18 }} />
        </a>
        <a
          href="https://www.instagram.com/bdroppy/"
          target="_blank"
          rel="noreferrer"
        >
          <Icon type="instagram" style={{ fontSize: 18 }} />
        </a>
        <a
          href="https://www.youtube.com/c/BDroppy"
          target="_blank"
          rel="noreferrer"
        >
          <Icon type="youtube" style={{ fontSize: 18 }} />
        </a>
        <a
          href="https://www.tiktok.com/@bdroppy"
          target="_blank"
          rel="noreferrer"
        >
          <InlineIcon icon="ic:baseline-tiktok" style={{ fontSize: 24 }} />
        </a>
      </div>
      <div className={`footer__terms ${isMobile && 'd-f fd-c'}`}>
        <p>{TRANSLATIONS.footer.bdroppy}</p>
        <Link href="/privacy-policy" passHref>
          <a
            style={{
              fontSize: '14px',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          >
            {TRANSLATIONS.sidebar.privacy}
          </a>
        </Link>
        <Link href="/terms-and-conditions" passHref>
          <a
            style={{
              fontSize: '14px',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          >
            {TRANSLATIONS.sidebar.terms}
          </a>
        </Link>
      </div>
      <style jsx>{style}</style>
    </div>
  );
}

export default Footer;
