import Icon from 'components/utils/Icon';
import { useTheme } from 'next-themes';
import { DARK_THEME_NAME, LIGHT_THEME_NAME } from 'tools/constants';
import { useEffect } from 'react';
import { useIsEmbedded } from '../../hooks/useIsEmbedded';

function SelectTheme() {
  const { resolvedTheme, setTheme } = useTheme();
  const isEmbedded = useIsEmbedded();
  useEffect(() => {
    if (isEmbedded) {
      setTheme(LIGHT_THEME_NAME);
    }
  }, [isEmbedded, setTheme]);
  function onClick() {
    setTheme(
      resolvedTheme === DARK_THEME_NAME ? LIGHT_THEME_NAME : DARK_THEME_NAME
    );
  }
  if (isEmbedded) {
    return null;
  }
  return (
    <div className="lang" onClick={onClick} style={{ cursor: '' }}>
      <div className="switch-theme-container d-f">
        <div
          className={`tab-switch ${
            resolvedTheme !== DARK_THEME_NAME && 'tab-switch-inactive'
          }`}
          onClick={onClick}
        >
          <Icon type="sun" />
        </div>
        <div
          className={`tab-switch ${
            resolvedTheme === DARK_THEME_NAME && 'tab-switch-inactive'
          }`}
          onClick={onClick}
        >
          <Icon type="moon" />
        </div>
        <div className="section-wrap" />
      </div>
      <style jsx>{`
        .switch-theme-container {
          border-radius: 100px;
          background: #eee;
          padding: 0.2em;
          display: flex;
        }
        .tab-switch {
          cursor: pointer;
          padding: 0.3em 0.6em;
        }
        .tab-switch-inactive {
          background: white;
          border-radius: 50px;
          padding: 0.3em 0.6em;
          box-shadow: var(--shadow-light);
        }
        .tab-switch :global(.icon) {
          font-size: 20px;
          font-weight: 500;
          color: var(--grey-light);
          opacity: 0.85;
        }
        .tab-switch-inactive :global(.icon) {
          opacity: 1;
          font-weight: 600;
          color: var(--grey-dark);
        }
      `}</style>
    </div>
  );
}

export default SelectTheme;
