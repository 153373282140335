import css from 'styled-jsx/css';

export default css`
  .menu {
    list-style-type: none;
    text-align: left;
    padding: 0;
  }
  .item {
    padding: 1.1875rem 1.25rem;
    margin: 0px;
    display: flex;
    cursor: pointer;
    user-select: none;
    font-size: 1rem;
    transition: 0.3s;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
    color: var(--li-sidenav);
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid var(--border-sidemanu);
  }
  .dropdown-item {
    padding: 1.1875rem 1.25rem;
    margin: 0px;
    display: flex;
    cursor: pointer;
    user-select: none;
    font-size: 1rem;
    transition: 0.3s;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
    color: var(--li-sidenav);
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #ebebeb;
  }
  .active {
    font-weight: 700;
  }
  .icon-chevron-down.active {
    font-size: 18px;
    right: 16px;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-weight: 500;
  }
  .icon-chevron-down {
    font-size: 18px;
    right: 16px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .item:hover,
  .dropdown-item:hover {
    background: var(--background);
  }
  .font-weight-bold {
    font-weight: 600;
  }
`;
