import React, { useCallback, useEffect, useRef, useState } from 'react';

import Icon from '../Icon';
import style from './index.style';

function Accordion({ className, label, children, customHeight }) {
  const [isOpen, setIsOpen] = useState(false);
  const childrenRef = useRef(null);
  const [height, setHeight] = useState(0);

  const handleOpenAccordion = useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, []);

  useEffect(() => {
    setHeight(childrenRef.current.getBoundingClientRect().height);
    // eslint-disable-next-line
  }, [childrenRef.current]);

  return (
    <div
      className={`accordion-container ${className}`}
      onClick={handleOpenAccordion}
    >
      <div className="accordion-head">
        <Icon
          type="chevron-right"
          className={`accordion-icon ${isOpen && 'accordion-icon-open'}`}
        />
        <span className="accordion-text">{label}</span>
      </div>
      {customHeight ? (
        <div
          className={`accordion-content`}
          style={{ height: isOpen ? customHeight : 0 }}
        >
          <div ref={childrenRef}>{children}</div>
        </div>
      ) : (
        <div
          className={`accordion-content`}
          style={{ height: isOpen ? height : 0 }}
        >
          <div ref={childrenRef}>{children}</div>
        </div>
      )}
      <style jsx>{style}</style>
    </div>
  );
}

export default Accordion;
