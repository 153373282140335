import css from 'styled-jsx/css';

export default css`
  .header__free {
    position: sticky;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    flex-direction: column;
    z-index: 4;
    align-self: flex-start;
    top: 0px;
  }
  .header__fixed {
    position: fixed;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    flex-direction: column;
    z-index: 4;
    top: 0px;
    width: calc(100% - 250px);
    grid-area: header;
  }
  .header__menu {
    padding: 8px 0px;
  }
  .header__menu {
    background: var(--bg-opacity);
  }
  .header__menu {
    width: 100%;
    border-bottom: var(--line-header);
    height: 100%;
  }
  .header__menu > div {
    height: 100%;
  }

  .header__menu-wrap {
    display: flex;
    max-width: 1440px;
    margin: auto;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0px 12px;
  }
  .header__menu-wrap-wide {
    display: flex;
    margin: auto;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0px 16px;
  }
  .header__menu-wrap > div,
  .header__menu-wrap-wide > div {
    flex-grow: 1;
    display: flex;
    width: 33%;
  }
  .header__menu-wrap-center {
    justify-content: center;
  }
  .header__menu-wrap-right {
    justify-content: end;
    align-items: center;
  }
  .header__menu-wrap-left {
    display: flex;
    justify-content: flex-start;
  }
  .header__menu-wrap-left > div {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 10px;
    transition: 0.3s;
    border-radius: 6px;
  }
  .header__menu-wrap-left-menu:hover {
    cursor: pointer;
    background: var(--background);
  }
  .header__menu-wrap-left > div :global(.icon) {
    font-size: 24px !important;
  }
  .header__menu-wrap-center-logo {
    background-image: var(--logo-login);
    height: 45px;
    background-repeat: no-repeat;
    width: 100%;
    max-width: 150px;
    background-size: contain;
    background-position: center;
    cursor: pointer;
  }
  :global(.header__menu-wrap-right-box > div) {
    width: 45px;
    height: 45px;
    align-items: center;
    margin: auto;
    display: flex;
    justify-content: center;
    cursor: pointer;
    border-radius: 100px;
    transition: 0.3s;
  }
  :global(.header__menu-wrap-right-box > div:hover) {
    background: var(--background);
  }
  .user button {
    border-radius: 50px;
    height: 40px;
    width: 40px;
    background: transparent;
    cursor: pointer;
  }
  @media only screen and (max-width: 992px) {
    .header__menu-wrap {
      padding: 0px 8px;
    }
    .header__menu {
      padding: 2px 0px;
    }
    .header__menu-wrap-wide {
      display: flex;
      margin: auto;
      width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0px 8px;
    }
    .header__fixed {
      position: sticky !important;
      right: 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      flex-direction: column;
      z-index: 4;
      top: 0px;
      width: 100% !important;
      grid-area: header;
    }
    .header__menu-wrap-center-logo {
      width: 100%;
      max-width: 90px;
    }
    :global(.header__menu-wrap-right-box > div) {
      width: 40px;
      height: 40px;
      align-items: center;
      margin: auto;
      display: flex;
      justify-content: center;
      cursor: pointer;
      border-radius: 100px;
      transition: 0.3s;
    }
  }
  .zindex-custom {
    z-index: 10;
  }

  @media only screen and (min-width: 992px) {
    .header-tablet {
      display: none !important;
    }
  }
  :global(.account) {
    color: var(--primary);
    cursor: pointer;
    text-decoration: underline;
  }
`;
