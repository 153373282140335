import { useEffect, useState } from 'react';

import Icon from './Icon';
import OutsideClickHandler from './OutsideClickHandler';

export default function DrawerUser({
  children,
  title,
  visible,
  width = '250px',
  zIndex = '10000000000000',
  onClose = () => {},
  onCloseDrawer,
  nested,
}) {
  const [isOpen, setIsOpen] = useState(visible);
  const drawerWidth = width;

  useEffect(() => {
    setIsOpen(visible);
  }, [visible]);

  function handleClose() {
    setIsOpen(false);
    onClose();
    if (onCloseDrawer) {
      onCloseDrawer();
    }
  }

  return (
    <span className={`drawer-wrapper ${isOpen ? 'open' : 'close'}`}>
      <div className={`${isOpen && 'overlay'}`}></div>
      <OutsideClickHandler onOutsideClick={handleClose}>
        <div className="drawer">
          <div onClick={handleClose} className="close-icon">
            {nested ? (
              <Icon type="arrow-right" />
            ) : (
              <Icon type="chevrons-left" />
            )}
          </div>
          <div className="content">
            <div className="inner">
              {title && <div className="title">{title}</div>}
              <div className="inner-content">{children}</div>
            </div>
          </div>
        </div>
      </OutsideClickHandler>
      <style jsx>{`
        :global(body .grid-container) {
          overflow: ${isOpen ? 'hidden!important' : ''};
          padding-right: ${isOpen ? '0px!important' : ''};
        }
        .title {
          font-size: 18px;
        }
        .overlay {
          position: fixed;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          background: rgb(0 0 0 / 50%);
          z-index: -1;
          opacity: 0;
          transition: opacity 0.4s;
        }
        .drawer-wrapper.open .overlay {
          z-index: 999;
          opacity: 1;
        }
        .drawer {
          position: fixed;
          height: 100%;
          width: ${width};
          background: var(--background-utils);
          top: 0;
          display: flex;
          flex-direction: column;
          right: 0;
          transition: 0.5s ease;
          transform: translateX(100%);
          padding: 0px;
          z-index: ${zIndex};
          overflow: hidden scroll;
        }
        .drawer-wrapper.open .drawer {
          transform: translateX(0px);
        }
        .close-icon {
          position: absolute;
          right: 10px;
          top: 10px;
          font-size: 22px;
          cursor: pointer;
        }
        .close-icon :global(.icon) {
          font-size: 24px;
        }
        .content,
        .inner,
        .inner-content {
          height: 100%;
        }
      `}</style>
    </span>
  );
}
