import css from 'styled-jsx/css';

export default css`
  .subheader {
    position: sticky;
    top: 45px;
    display: flex;
    grid-area: subheader;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    overflow-x: scroll;
    overflow-y: hidden;
    z-index: 3;
  }
  .subheader::-webkit-scrollbar,
  .subheader__submenu::-webkit-scrollbar {
    display: none;
  }
  .subheader__submenu {
    background: var(--background-subheader);
    backdrop-filter: saturate(180%) blur(20px);
    padding: 8px 16px;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .subheader__submenu {
    width: 100%;
    border-bottom: var(--line-header);
    height: 100%;
  }
  .subheader__submenu > div {
    height: 100%;
  }
  .subheader__submenu-wrap {
    display: flex;
    max-width: 1300px;
    margin: auto;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }
  .subheader__submenu-wrap-list {
    display: flex;
    gap: 10px;
    width: 100%;
    white-space: nowrap;
    font-size: 14px;
  }
  .subheader__submenu-wrap-list li {
    width: 100%;
    scroll-snap-align: start;
    scroll-snap-stop: always;
    padding: 0px 6px;
  }
  .subheader__submenu-wrap-list li {
    position: relative;
    cursor: pointer;
  }

  .subheader__submenu-wrap-list li:after {
    background: none repeat scroll 0 0 transparent;
    top: -12px;
    content: '';
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: var(--foreground);
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  .subheader__submenu-selected {
    font-weight: 600;
  }
  .subheader__submenu-selected:after {
    width: 100% !important;
    left: 0 !important;
  }

  .subheader__submenu-wrap-list li:hover:after {
    width: 100%;
    left: 0;
  }
  @media only screen and (min-width: 768px) {
    .subheader__submenu {
      overflow: auto;
      padding: 14px 16px 13px 16px;
    }
    .subheader {
      overflow: auto;
      top: 62px;
    }
    .subheader__submenu-wrap p {
      font-size: 15px;
    }
    .subheader__submenu-wrap-list {
      width: auto;
      font-size: 15px;
      gap: 20px;
      align-items: center;
    }
  }
  .cashback {
    font-size: 14px;
    font-weight: 500;
    border-radius: 6px;
    background: #60f3d754;
  }
  .cashback-amount {
    font-size: 15px;
    font-weight: 700;
  }
  .section-cashback {
    border-radius: 6px;
    position: relative;
    backgrund: var(--background-utils);
  }
`;
