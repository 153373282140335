import Button from 'components/utils/Button';
import { useCallback, useRef, useState } from 'react';
import Portal from './Portal';
import { generateString } from '../../tools/utils';

const ButtonDropdown = ({
  children,
  className,
  label,
  iconBefore,
  iconAfter,
  img,
}) => {
  const [isActive, setIsActive] = useState(false);
  const dropdownRef = useRef(null);
  const handleDropdown = () => {
    setIsActive(!isActive);
  };
  const handleCloseDropDown = useCallback(() => {
    setIsActive(false);
  }, []);

  const uuid = generateString(10);

  return (
    <div className="relative" id={`react-portal-dropdown-container-${uuid}`}>
      <Button
        className={className}
        label={label}
        onClick={handleDropdown}
        iconBefore={iconBefore}
        iconAfter={iconAfter}
        img={img}
      />
      {isActive && (
        <Portal
          wrapperId={`react-portal-dropdown-container-${uuid}`}
          onClose={handleCloseDropDown}
          childrenRef={dropdownRef}
        >
          <div
            className={isActive ? 'dropdown-button active' : 'dropdown-button'}
            onClick={handleDropdown}
            ref={dropdownRef}
          >
            {children}
          </div>
        </Portal>
      )}
      <style>
        {`
            .relative{
                position:relative;
            }
            .dropdown-button{                
                background:var(--sort-background);
                top:-5px;
                position:absolute;
                transition:.3s;                
                opacity:0;
                display:block;
                right:0;
                visibility:hidden;
                font-weight:600;
                font-size:15px;
                z-index:15;
                width:100%;
                min-width:150px;
            }
            .dropdown-button.active{
                display:block;
                top:45px;
                visiblity:visible;
                opacity:1;
                box-shadow: 0 10px 20px rgba(0,0,0,0.10), 0 6px 6px rgba(0,0,0,0.15);
                visibility:visible;
                width:100%;
                min-width:150px;
                border-radius:5px;
            }
            `}
      </style>
    </div>
  );
};

export default ButtonDropdown;
