import css from 'styled-jsx/css';

export default css`
  .footer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 1em 0em;
    flex-direction: column;
    grid-area: footer;
    background: var(--background-home-2);
  }
  .footer__menu {
    display: flex;
    max-width: 1480px;
    justify-content: space-between;
    width: 100%;
    margin: auto;
    border-bottom: none;
    padding: 1em 1em;
  }
  .footer__info,
  .footer__social {
    display: flex;
    max-width: 1280px;
    width: 100%;
    justify-content: center;
    padding: 1em;
  }
  .footer__terms {
    display: flex;
    max-width: 1280px;
    width: 100%;
    justify-content: center;
    padding: 1em 1em;
  }
  .footer__social,
  .footer__terms {
    gap: 20px;
  }
  .footer__social :global(.icon) {
    font-size: 22px !important;
  }
  .footer__menu-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    padding: 0;
    gap: 16px;
  }
  .footer__menu-box p {
    cursor: pointer;
  }
  .footer__menu-box p:hover {
    text-decoration: underline;
  }
  .footer__menu-box h6 {
    font-size: 16px;
    letter-spacing: 0.5px;
    font-weight: 600;
  }
  .footer__menu-box p {
    font-size: 15px;
    font-weight: 400;
  }
  .footer__terms a {
    font-size: 14px;
    text-decoration: underline;
  }
  .footer__terms p {
    font-size: 14px;
  }
  .footer :global(.accordion-container) {
    border-bottom: var(--line);
    padding: 16px;
    margin-top: 0;
  }
  .footer :global(.accordion-text) {
    font-size: 16px;
    margin: 0;
    font-weight: 600;
    text-align: left;
    color: var(--foreground);
  }
  .footer :global(.accordion-icon) {
    font-size: 22px;
    font-weight: 600;
    right: 16px;
    color: var(--foreground);
  }
  .footer__accordion {
    gap: 10px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
  }
  .payment img {
    max-width: 40px;
  }
  @media only screen and (max-width: 600px) {
    .footer__menu {
      padding: 1em 0 1em !important;
    }
    .footer__info,
    .footer__social {
      display: flex;
      max-width: 1280px;
      width: 100%;
      justify-content: flex-start;
      padding: 1em;
    }
  }
  @media only screen and (min-width: 992px) {
    .footer {
      padding: 3em 0;
      grid-area: footer;
    }
    .footer__menu {
      display: flex;
      max-width: 1280px;
      width: 100%;
      margin: auto;
      border-bottom: solid thin #ccc;
      padding: 1em 0 2em;
    }
    .footer__info,
    .footer__social {
      display: flex;
      max-width: 1280px;
      width: 100%;
      justify-content: center;
      padding: 2em 0;
    }
    .footer__menu-box {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      text-align: center;
      padding: 0.5em;
      width: 25%;
      gap: 16px;
    }
    .footer__menu-box h6 {
      font-size: 16px;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      font-weight: 600;
    }
    .footer__terms {
      display: flex;
      max-width: 1280px;
      width: 100%;
      justify-content: center;
      padding: 1em 0 0;
    }
    .link {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  :global(.swiper-review) {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    gap: 5px;
  }
  :global(.swiper-review) {
    background: white;
    border-radius: 8px;
    padding: 1em;
    min-height: 280px;
  }
  .review-title {
    color: var(--scuro-x-testi, #2a2c41);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .review {
    color: var(--scuro-x-testi, #2a2c41);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
  .bg-reviews {
    background: #ccffee !important;
    width: 100%;
  }
  :global(.swiper-reviews) {
    padding-bottom: 55px;
  }
  .reviews-background-padding {
    padding: 2em 0em 0em !important;
  }
  @media only screen and (max-width: 768px) {
    .detail-subcategory-content :global(.swiper-slide),
    .detail-brand-content :global(.swiper-slide) {
      max-width: calc(50%);
      margin: 0px 6px;
    }
    .swiper-content :global(.swiper-slide) {
      max-width: calc(65%);
      margin: 0px 6px;
    }
    .detail-subcategory-content :global(.swiper-slide:first-child),
    .detail-brand-content :global(.swiper-slide:first-child),
    .swiper-content :global(.swiper-slide:first-child),
    :global(.thumbnails .swiper-slide:first-child) {
      margin-left: 1em !important;
    }
    .detail-subcategory-content :global(.swiper-slide:last-child),
    .detail-brand-content :global(.swiper-slide:last-child),
    .swiper-content :global(.swiper-slide:last-child),
    :global(.thumbnails .swiper-slide:last-child) {
      margin-right: 1em !important;
    }
  }
  .detail-subcategory-content :global(.swiper-slide),
  .detail-brand-content :global(.swiper-slide) {
    display: inherit;
    max-width: calc(50% - 15px);
    cursor: pointer;
  }
  @media screen and (min-width: 768px) {
    .detail-subcategory-content :global(.swiper-slide),
    .detail-brand-content :global(.swiper-slide) {
      display: inherit;
      max-width: calc(24% - 15px);
    }
    .swiper-content :global(.swiper-slide) {
      display: inherit;
      max-width: calc(33% - 15px);
    }
  }
  .swiper-content {
    max-width: 1200px;
    margin: auto;
  }
  .rating :global(svg) {
    color: #fd8d09;
    font-size: 25px;
  }
  .padding-md {
    padding: 0em;
  }
  @media screen and (max-width: 768px) {
    .padding-md {
      padding: 0em 1em;
    }
  }
  .icon-footer {
    width: 60px;
    height: 60px;
    background: #3f9df4;
    border-radius: 100px;
  }
`;
