import React, { useCallback, useState } from 'react';
import style from './index.style';

const DropdownItem = (props) => {
  const { item } = props;
  const handleRouteChange = props.handleRouteChange;
  const { label, to, children } = item;
  const [expanded, setExpand] = useState();
  const resolveLinkPath = useCallback((childTo, parentTo) => {
    if (childTo) {
      return `${parentTo}&${childTo}`;
    } else {
      return `${parentTo}`;
    }
  }, []);
  const onExpandChange = useCallback((e) => {
    e.preventDefault();
    setExpand((prevState) => !prevState);
  }, []);

  return (
    <div className={'dropdown-item relative'}>
      <div className={'item'} onClick={onExpandChange}>
        <a
          onClick={() => handleRouteChange(to)}
          className={expanded && 'active'}
        >
          {label}
        </a>
        <span
          className={
            expanded ? 'icon-chevron-down active' : 'icon-chevron-down'
          }
        />
      </div>
      {expanded && (
        <div className={`mb-md`}>
          {children.map((item, i) => {
            const { label, children } = item;
            if (children) {
              return (
                <div key={label} className={'dropdown-children'}>
                  <DropdownItem
                    item={{
                      ...item,
                      to: resolveLinkPath(item.to, props.item.to),
                    }}
                    handleRouteChange={handleRouteChange}
                  />
                </div>
              );
            }

            return (
              <div key={i} className={'item-children'}>
                <a
                  onClick={() =>
                    handleRouteChange(resolveLinkPath(item.to, props.item.to))
                  }
                >
                  <span>{label}</span>
                </a>
              </div>
            );
          })}
        </div>
      )}
      <style jsx>{style}</style>
    </div>
  );
};

export default DropdownItem;
